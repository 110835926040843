const CheckBox = ({ title, value, setValue }: { title: string; value: boolean; setValue: (b: boolean) => void }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label>{title}</label>
      <input
        type="checkbox"
        onChange={(e) => {
          setValue(e.target.checked);
        }}
        checked={value}
      />
    </div>
  );
};

export default CheckBox;
