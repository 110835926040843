import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate, useNavigation } from 'react-router-dom';
import { accessTokenAtom, orgAtom } from '../atoms';
import Checkbox from '../components/Checkbox';
import TextInput from '../components/TextInput';
import { REACT_APP_API_DOMAIN } from '../env';
export default function AddOrganisation() {
  const navigate = useNavigate();
  const [accessToken] = useAtom(accessTokenAtom);
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [salesName, setSalesName] = useState('');
  const [salesEmail, setSalesEmail] = useState('');
  const [salesPhone, setSalesPhone] = useState('');
  const [isLive, setIsLive] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const queryClient = useQueryClient();

  const [org, setOrg] = useAtom(orgAtom);
  useEffect(() => {
    if (org) {
      setName(org.name);
      setLocation(org.location);
      setCountry(org.country);
      setCity(org.city);
      setSalesName(org.sales_team.name);
      setSalesEmail(org.sales_team.email);
      setSalesPhone(org.sales_team.phone);
      setIsLive(org.isLive);
    }
  }, [org]);

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
  }),
    [accessToken];
  const handleSubmit = async () => {
    const newOrg = {
      name,
      location,
      country,
      city,
      isLive,
      sales_team: {
        name: salesName,
        email: salesEmail,
        phone: salesPhone,
      },
    };
    let url = REACT_APP_API_DOMAIN + '/admin/organisation';
    const headers = new Headers();
    headers.append('Authorization', accessToken);
    headers.append('Content-Type', 'application/json');
    const options = { method: 'POST', body: JSON.stringify(newOrg), headers };
    if (org) {
      url += '/' + org.id;
      options.method = 'PUT';
    }
    await fetch(url, options);
    queryClient.invalidateQueries();
    setOrg(undefined);
    navigate('/editorg');
  };

  const handleDelete = async () => {
    if (!org) {
      alert('Something went wrong');
      return;
    }
    if (!canDelete) {
      setCanDelete(true);
      alert(
        'Warning: This will delete the org and all homes, caregivers and listeners associated with it. Please select delete again if you are sure you would like to do this'
      );
      return;
    }
    const url = REACT_APP_API_DOMAIN + '/admin/organisation/' + org?.id;
    const headers = new Headers();
    headers.append('Authorization', accessToken);
    headers.append('Content-Type', 'application/json');
    const options = { method: 'DELETE', headers };
    const response = await fetch(url, options);
    queryClient.invalidateQueries();
    setOrg(undefined);
    navigate('/editorg');
  };
  return (
    <>
      <div>Organisation Information</div>
      <TextInput title="name" value={name} setValue={setName} />
      <TextInput title="location" value={location} setValue={setLocation} />
      <TextInput title="country" value={country} setValue={setCountry} />
      <TextInput title="city" value={city} setValue={setCity} />
      <Checkbox title="is live" value={isLive} setValue={setIsLive} />
      <div>Sales Team information</div>
      <TextInput title="name" value={salesName} setValue={setSalesName} />
      <TextInput title="email" value={salesEmail} setValue={setSalesEmail} />
      <TextInput title="phone" value={salesPhone} setValue={setSalesPhone} />
      <button onClick={() => handleSubmit()}>{org ? 'save' : 'add'}</button>
      {org ? <button onClick={() => handleDelete()}>delete</button> : null}
      <button
        onClick={() => {
          setOrg(undefined);
          navigate(-1);
        }}
      >
        Back
      </button>
    </>
  );
}
