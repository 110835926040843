import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, idTokenAtom } from '../atoms';
import { REACT_APP_ENV, REACT_APP_COGNITO_CLIENT_ID } from '../env';
const handleLogin = () => {
  const currentUrl = window.location.href.split('#')[0];
  let callbackUrl = currentUrl;
  callbackUrl = encodeURIComponent(callbackUrl);
  const link =
    `https://vera-pro-user-pool-${REACT_APP_ENV}.auth.ap-southeast-2.amazoncognito.com/login?client_id=${REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=email+openid+profile&redirect_uri=` +
    callbackUrl;
  console.log(link);
  // eslint-disable-next-line no-restricted-globals
  location.replace(link);
};
export default function Home() {
  const navigate = useNavigate();
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const handleReturn = async () => {
    // eslint-disable-next-line no-restricted-globals
    const hash = location.hash;
    const newHash = hash.replace('#', '?');
    if (newHash) {
      const urlParams = new URLSearchParams(newHash);
      const tokenId = urlParams.get('id_token');
      const accessToken = urlParams.get('id_token');
      if (tokenId) {
        setIdToken(tokenId || '');
      }
      if (accessToken) {
        setAccessToken(accessToken);
      }
    }
  };
  useEffect(() => {
    handleReturn();
  }, []);

  console.log({ REACT_APP_ENV, REACT_APP_COGNITO_CLIENT_ID });
  return (
    <>
      {idToken ? (
        <>
          <button onClick={() => navigate('/orgform')}>Add new Org</button>
          <button onClick={() => navigate('/editorg')}>Edit Org</button>
          <button onClick={() => navigate('/deleteverauser')}>Delete Vera User</button>
          <button onClick={() => setIdToken('')}>Logout</button>
        </>
      ) : (
        <button onClick={handleLogin}>Login</button>
      )}
    </>
  );
}
