export default function TextInput({
  title,
  value,
  setValue,
}: {
  title: string;
  value: string;
  setValue: (s: string) => void;
}) {
  return (
    <div style={{ display: 'flex', marginTop: 10 }}>
      <div style={{ width: 100 }}>{title + ':'}</div>
      <textarea
        onChange={(event) => {
          setValue(event.target.value);
        }}
        value={value}
      />
    </div>
  );
}
