import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom } from '../atoms';
import { REACT_APP_API_DOMAIN } from '../env';

export default function DeleteVeraUser() {
  const navigate = useNavigate();
  const [accessToken] = useAtom(accessTokenAtom);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
  }),
    [accessToken];

  const getVeraUsers = async () => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + '/admin/users', {
      headers,
    });
    return (await resp.json()) as VeraUser[];
  };
  const { data: veraUsers, isLoading, isError } = useQuery({ queryKey: ['getVeraUsers'], queryFn: getVeraUsers });

  const deleteVeraUser = async (veraUser: VeraUser) => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + `/users/${veraUser.id}`, {
      headers,
    });
    console.log('resp delete Vera User', JSON.stringify(resp));
    alert(`Vera User ${veraUser.name}  deleted`);
    queryClient.invalidateQueries(['getVeraUsers']);
  };

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (isError) {
    return <div>Something went wrong. Please refresh</div>;
  }

  console.log('veraUsers', veraUsers);

  const rowStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px',
    padding: '10px',
    alignItems: 'center',
    borderBottom: '1px solid #000', // Add this line
    width: '60%', // Add this line to make the border stretch across the screen
  };

  return (
    <>
      <button onClick={() => navigate('/home')}>back</button>
      <div style={{ flexDirection: 'column', marginBottom: '20px' }}>Delete Vera Users</div>
      <div style={rowStyle}>
        <div style={{ width: '15%' }}>ID</div>
        <div style={{ width: '35%' }}>Name</div>
        <div style={{ width: '35%' }}>Email</div>
        <div style={{ width: '15%' }}>Delete</div>
      </div>
      {veraUsers.length ? (
        veraUsers.map((c) => (
          <div key={c.id} style={rowStyle}>
            <div style={{ width: '15%' }}>{c.id}</div>
            <div style={{ width: '35%', wordWrap: 'break-word', whiteSpace: 'normal' }}>{c.name}</div>
            <div style={{ width: '35%', wordWrap: 'break-word', whiteSpace: 'normal' }}>{c.email}</div>
            <div style={{ width: '15%', marginLeft: '10px' }}>
              <button
                onClick={() => {
                  deleteVeraUser(c);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))
      ) : (
        <div>No Vera Users</div>
      )}
    </>
  );
}
